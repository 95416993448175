
import { defineComponent, reactive, onMounted, computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ethitransService } from "@/services/EthitransService";
import ErrorList from "@/components/commonDesign/ErrorList.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import ProjectImageUploadModal from "@/components/modal/ProjectImageUploadModal.vue";

// import MembersCard from "@/components/cards/MembersCard.vue";
import TemplateCard from "@/components/cards/TemplateCard.vue";
// import ChannelCard from "@/components/cards/ChannelCard.vue";
// import ChannelCardTest from "@/components/cards/ChannelCardTest.vue";
import HistoryCard from "@/components/cards/HistoryCard.vue";
import EmptyData from "@/components/commonDesign/EmptyData.vue";
import ProjectApprovalModal from "@/components/projectDetail/ProjectApprovalModal.vue";
import ProjectApprovalModalEdit from "@/components/projectDetail/ProjectApprovalModalEdit.vue";
import ToDoDetailsAttachment from "@/components/todo/ToDoDetailsAttachment.vue";
import GoogleMapView from "@/components/commonDesign/GoogleMapView.vue";

import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'

import { validationService } from "@/common/ValidationService";
import { common } from "@/common/services/Common";
import { config } from "@/Config";
import Pusher from "pusher-js";
import { useStore } from "vuex";
import introJs from "intro.js";
import state from "pusher-js/types/src/core/http/state";
// import AppTour from "@/components/modal/AppTour.vue";
import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css';
import Multiselect from 'vue-multiselect';
import { VueDraggableNext } from 'vue-draggable-next'
import { useCookie } from 'vue-cookie-next'

import AppPhoneNumberInput from "@/components/commonDesign/AppPhoneNumberInput.vue"

export default defineComponent({
  name: "ProjectDetails",
  props: {
    msg: String,
  },
  components: {
    UpgradeSubscriptionModal,
    ToDoDetailsAttachment,
    ProjectApprovalModal,
    ProjectApprovalModalEdit,
    // MembersCard,
    TemplateCard,
    SuccessfulModalCustom,
    // ChannelCard,
    // ChannelCardTest,
    HistoryCard,
    EmptyData,
    ErrorList,
    Treeselect,
    // 'v-select' : vSelect
    Multiselect,
    draggable: VueDraggableNext,
    ProjectImageUploadModal,
    GoogleMapView,
    AppPhoneNumberInput,
  },
  setup() {
    const cookie = useCookie();
    const selectedValue = ref(2) as any;
    const selectedValueForPending = ref(2) as any;
    const selectedDateForMember = ref('') as any;
    const selectedDateForPendingMember = ref('') as any;
    const phoneNumber = ref('');
    const options = ref([]) as any;
    const latitude = ref<number>(36.0755227);  // Default latitude
    const longitude = ref<number>(-79.8203066);  // Default longitude
    const address = ref<string>('');
    const isEdit = ref<boolean>(false);

    const updateLatitude = (newLatitude: number) => {
      latitude.value = newLatitude;
    };

    const updateLongitude = (newLongitude: number) => {
      longitude.value = newLongitude;
    };

    const updateAddress = (newAddress: string) => {
      address.value = newAddress;
    };
    const selectedOptions = ref([]) as any;
    const isMobile = ref(false);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const selectedChannels = ref({}) as any;
    const allChannels = ref([]) as any;
    const selectedUsers = ref({}) as any;
    const allUsers = ref([]) as any;
    const channelLists = ref([]) as any;
    const channelListSelected = ref({
      'label': 'Search and select ',
      'name': 'Search and select',
      'code': 'Search and select'
    }) as any;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const {
      params: { projectId },
    } = useRoute();
    // console.log("CHECK ID, >>", projectId);
    const state = reactive({
      mobile: "",
      titleMember: "pending members",
      headingMember: "Get started by inviting users in your project.",
      showCheckChannelMessage: true,
      value: [] as any,
      valueConsistsOf: 'LEAF_PRIORITY',
      phaseOptions: [] as any,
      phaseTask: [] as any,
      allCreatedChannel: [] as any,
      coOwner: [] as any,
      isIcludeCoOwner: false,
      checkActivity: false,
      inputError: false,
      duplicate: false,
      selectPrivateChannel: '' as any,
      data: {
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        selectedProject: {} as any,
        selectedProjectEdit: {
          projectName: "",
          projectDescription: "",
          projectTags: "",
          projectAddress: "",
        } as any,
        currentChannel: '' as any,
        isChannelCreated: false,
        subTitleMessage: "",
        channelId: '' as any,
        channelStatus: "active",
        allProject: '' as any,
        allChannel: '' as any,
        allMember: '' as any,
        search: "" as any,
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          appointment_numbers: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          archive_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_file_size: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_message_history: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          estimation_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          export_channel_pdf: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          files_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          folder_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          guests_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          import_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_payment: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_in_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_per_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          members_per_appointment: 0,
          name: "Silver",
          // eslint-disable-next-line @typescript-eslint/camelcase
          pdf_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase_per_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          private_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sync_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          task_per_phase: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          template_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_channel_member: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_estimation: 0,
        } as any,
        activeUserEmail: localStorage.getItem("current_user_email"),
        projectRole: '' as any,
        privateChatChannel: [] as any,
        estimationChatChannel: [] as any,
        selectSharePhase: [] as any,
        shareProjectMember: [] as any,
        phaseChatChannel: [] as any,
        approvalChatChannel: [] as any,
        todo: {
          payerEmail: "",
        },
        selectedChannel: {} as any,
        projectAckowledgement: {
          payer: "",
          payers: [],
          name: "",
          description: "",
          estimatedBudget: "",
          startDate: "",
          endDate: "",
          attachments: [],
          edocLink: "",
        },
        selectedEditChannel: {
          name: "",
          description: "",
        } as any,
        selectedValidityDate: "" as any,
        channelIndex: 0,
        showProjectApprovalModal: false,
        showProjectImageUploadModal: false,
        inviteAsGuest: false,
        inviteAsPublicUser: false,
        successFulMessage: "",
        showSuccesCustomModal: false,
        invitationVerifyMsg: "",
        invitationProjectVerifyMsg: "",
        invitationVerify: {
          showStatus: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
        invitationProjectVerify: {
          showStatus: false,
          isIndividual: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
        invitationProjectEdit: {
          email: '' as any,
          showStatus: false,
          editId: '' as any,
          isIndividual: false,
          editPhase: [] as any,

        },
        selectedPendingMember: {} as any,
        imageBaseUrl: "",
        channelList: [] as any,
        pendingList: [] as any,
        memberList: [] as any,
        selectedChannelByProject: {
          channel: {} as any,
        },
      },
      ui: {
        successMessage: '',
        isActive: {
          upgradeSubscription: false,
        },
        modal: {
          showProjectAcknowledgementModal: false,
          showEditProjectAcknowledgementModal: false,
          selectedFromChannel: false,
        },
        isLoading: {
          projectDetailsAPICalled: false,
        },
        isPostLoading: {
          modifyHistoryDateForMember: false,
          privateChat: false,
          createProjectToDo: false,
          saveEditChannel: false,
          setNewValidity: false,
          deletePendingInvitation: false,
          getChannelByProject: false,
          verifyInvitation: false,
          verifyProjectInvitation: false,
          projectMemberListByChannel: false,
          resendInvitation: false,
          channelShow: false,
          channelHide: false,
          projectFav: false,
          projectFavRemove: false,
        },
        errors: {
          errorList: {
            modifyHistoryDateForMember: [] as any,
            createToDo: [] as any,
            saveEditChannel: [] as any,
            errorList: [] as any,
            resendInvitation: [] as any,
            removeProjectRole: [] as any,
            projectFav: [] as any,
            projectFavRemove: [] as any,
            saveEditProject: [] as any,
          },
          hasError: false,
        },
      },
      filter: {
        type: "all",
        projectId: 0,
        channelId: 0,
        sortBy: -1,
        title: 1,
        selected: { name: "Newest First", id: -1 } as any,
      },
      sendChannelId: [] as any,
      searchBy: "",
      errorList: [] as any,
      isErrorStatus: false,
      isCreateProjectModalShow: false,
      activityTitle: "activities",
      activityHeading: "Get started by creating activity in your project.",
      title: "channels",
      headingPrivate: "Private channel with members.",
      headingApproval:
        "Channel created for Approval with payers and owners of the project.",
      headingPhase: "Channels of Phase/Task Invitation.",
      titleForTemplate: "templates",
      headingForTemplate: "Get started by adding template in your project.",
      titleForMember: "members",
      headingForMember: "Add new members to channel in your project.",
      todoTitle: "todos",
      channelTitle: "channels",
      todoHeading: "Add new todo in your project.",
      channelHeading: "Add new channel in your project.",
      isLoading: {
        getUserList: false,
        getProjectDetail: false,
        getProjectRoleList: false,
        editProjectShare: false,
        getProjectChannel: false,
        searchProjectChannel: false,
      },
      postLoading: {
        getUserList: false,
        createChannel: false,
        inviteAllUsers: false,
        changeProjectRole: false,
        removeProjectRole: false,
        getProjectDetail: false,
        inviteProjectUsers: false,
        removeShareInvitation: false,
        saveEditProject: false,

      },
      selectedMemberRole: 0,
      selectedMember: {} as any,
      selectedAllChannel: [] as any,
      selectedMemberForRemoval: {} as any,
      selectedShareInvitationForRemoval: {} as any,
      projectList: [],
      channelList: [] as any,
      memberList: [],
      activeMemberList: [] as any,
      pendingList: [],
      activityList: [],
      userList: [],
      shareProjectUserList: [] as any,
      activeMember: {} as any,
      roleList: [],
      templateList: [],
      projectDetail: {} as any,
      todoData: {} as any,
      activeUser: {} as any,
      invitationMessage: "",
      invitationProjectMessage: "Invite Sucessfully",
      existingMember: "",
      inviteMember: "",
      inviteAsPublic: [] as any,
      invite: {
        users: [] as any[],
        invites: [] as any[],
        filteredUsers: [] as any[],
        filteredInvites: [] as any[],
      },
      createChannel: {
        name: "",
        description: "",
      },
      error: {
        hasError: false,
        errorList: {
          createChannel: [] as any,
        },
      },
    });


    // const disableCheckInvities = computed(() => {
    //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   state.invite.invites.forEach((item: any, index: number) => {
    //     if (state.invite.invites.length != index) {
    //       console.log("CHECK HERE")
    //     }
    //     if (emailPattern.test(item.email)) {
    //       item.mailDisable = true;
    //     } else {
    //       item.mailDisable = false;
    //     }
    //     if (!item.phone) {
    //       item.smsNotification = false;
    //     } else {

    //       if (item.phone.length > 11) {
    //         item.smsNotification = true;
    //       } else {
    //         item.smsNotification = false;
    //       }
    //     }

    //   })
    //   return state.invite.invites;
    // })
    const hasValidLastContact = computed(() => {
      if (state.invite.invites.length === 0) {
        return false;
      }
      // state.invite.invites.forEach((member: any) => {
      //   // track the notify by is populated only once when information is populated
      //   member.mapNotifyByEmail = false;
      //   member.mapNotifyBySms = false;

      // })
      const members = state.invite.invites;

      const lastMember = members[members.length - 1];
      const hasValidEmail = lastMember?.email ? validationService.isValidEmail(lastMember.email) : false;
      const hasValidMobile = lastMember?.phone ? validationService.validateMobileNumber(lastMember.phone) : false;
      if (hasValidEmail) {
        lastMember.mailNotification = true;
        lastMember.mapNotifyByEmail = true;
      } else {
        lastMember.mailNotification = false;
      }
      if (hasValidMobile) {
        lastMember.smsNotification = true;
        lastMember.mapNotifyBySms = true;
      } else {
        lastMember.smsNotification = false;
      }


      // Return true if either the email or mobile number is valid
      return hasValidEmail || hasValidMobile;
    });
    // const hasValidLastContact = computed(() => {
    //   const members = state.invite.invites;

    //   if (!members.length) {
    //     return false;
    //   }

    //   const lastMember = members[members.length - 1];

    //   if (!lastMember?.first_name || !lastMember?.last_name) {
    //     return false;
    //   }

    //   const hasValidEmail = validationService.isValidEmail(lastMember?.email ?? '');
    //   const hasValidMobile = validationService.validateMobileNumber(lastMember?.phone ?? '');
    //   if (hasValidEmail) {
    //     // lastMember.mailNotification = true;
    //     lastMember.mailNotification = !lastMember.mailNotification;
    //   } 
    //   // else {
    //   //   lastMember.mailNotification = false;
    //   // }
    //   if (hasValidMobile) {
    //     // lastMember.smsNotification = true;
    //     lastMember.smsNotification = !lastMember.smsNotification;
    //   } 
    //   // else {
    //   //   lastMember.smsNotification = false;
    //   // }


    //   const mailNotification = lastMember?.mailNotification ?? false;
    //   const smsNotification = lastMember?.smsNotification ?? false;

    //   // At least one notification method should be true
    //   const isNotificationValid = mailNotification || smsNotification;

    //   // Check for valid contact details based on notification preferences
    //   const isContactValid = (mailNotification && hasValidEmail) || (smsNotification && hasValidMobile);

    //   // Return true if both notification and contact are valid
    //   return isNotificationValid && isContactValid;
    // });


    function showTour(dataId: any, title: any, message: any) {
      introJs()
        .setOptions({
          steps: [
            // dashboard User Guides
            {
              tooltipClass: "Tooltipproject",
              title: title,
              element: document.getElementById(
                dataId
              ) as HTMLElement,
              intro: message,
            }
          ],
        })
        .start();
    }
    function privateChat(member: any) {
      console.log("Private CHAT >>>", member.user);
      console.log("Private UUID >>>", member.user.uuid);
      member.isLoading = true;
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = false;
      const activeProject: any = projectId;
      if (state.ui.isPostLoading.privateChat) return false;
      member.isInProcess = true;
      const memberUUID: any = member.user;
      console.log("Private CHAT >>>", memberUUID.uuid);
      state.ui.isPostLoading.privateChat = true;
      ethitransService
        .phasePrivateChannelByPhaseIdUserId("", member.user.uuid, activeProject)
        .then((res: any) => {
          member.isLoading = true;
          console.log("privateChat Response Check:>>> ", res.data.data);
          if (res.data.data.channel && res.data.data.channel.uuid) {
            router.push({
              name: "admin-channel",
              params: { channelId: res.data.data.channel.uuid.toString() },
            });
          }
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.privateChat = false;
        });
    }
    function showProjectAcknowledgementRequestDetails() {
      router.push({
        name: "admin-acknowledgement-info",
        params: { projectId: projectId },
      });
      // $("#projectAcknowledgementRequestDetails").modal("show");
    }
    function hideProjectAcknowledgementRequestDetails() {
      $("#projectAcknowledgementRequestDetails").modal("hide");
    }
    function resendProjectRequest() {
      state.ui.modal.showEditProjectAcknowledgementModal =
        !state.ui.modal.showEditProjectAcknowledgementModal;
      hideProjectAcknowledgementRequestDetails();
    }
    function viewSelectedPhaseDetails(phaseDetails: any) {
      // console.log("viewSelectedPhaseDetails");
      router.push({
        name: "user-todo-details",
        params: { phaseId: phaseDetails.uuid },
      });
    }

    function shareProjectInvitationDetails(uuid: any) {
      router.push({
        name: "invite-share-project-details",
        params: { invitationId: uuid },
      });
    }

    function hideEditProjectAcknowledgementModal() {
      // $("#projectAcknowledgement").modal("show");
      state.ui.modal.showEditProjectAcknowledgementModal =
        !state.ui.modal.showEditProjectAcknowledgementModal;
    }
    function hideProjectAcknowledgementModal() {
      // $("#projectAcknowledgement").modal("show");
      state.ui.modal.showProjectAcknowledgementModal =
        !state.ui.modal.showProjectAcknowledgementModal;
    }
    function showProjectAcknowledgementModal() {
      // $("#projectAcknowledgement").modal("show");
      state.ui.modal.showProjectAcknowledgementModal = true;
      // state.ui.modal.showProjectAcknowledgementModal =
      //   !state.ui.modal.showProjectAcknowledgementModal;
    }

    watch(
      () => state.data.inviteAsGuest,
      () => {
        if (!state.data.inviteAsGuest) return false;
        // console.log("Guest Invitation Selected");
        state.existingMember = "";
        state.invite.users = [];
      }
    );
    function addNewMember() {
      if (state.errorList.length && state.isErrorStatus) {
        if (state.invite.invites && state.invite.invites.length) {
          state.invite.invites.forEach((item: any) => {
            if (item.first_name || item.last_name) {
              item.fNameError = false;
              item.lNameError = false;
              state.errorList = [];
              state.isErrorStatus = false;
            }

          })
        }
      }
      state.invite.invites.push({
        'email': "",
        'first_name': '',
        'last_name': '',
        'phone': '',
        'mailNotification': false,
        'smsNotification': false,
        'mailDisable': false,
      });
    }

    watch(
      () => state.data.inviteAsPublicUser,
      () => {
        if (!state.data.inviteAsPublicUser) {
          state.invite.invites = [];
          addNewMember()
        }
        if (!state.data.inviteAsPublicUser) return false;
        // console.log("Guest Invitation Selected");
        state.existingMember = "";
        state.invite.users = [];
        state.inviteAsPublic = [];
        state.invite.invites = [];
        state.inviteAsPublic.push({
          name: "",
          email: "",
          phone: "",
          smsNotification: false,
          mailNotification: true,
        });
      }
    );
    function redirectToDoc(link: any) {
      window.open(link, "_blank");
    }
    function hideUserProfile() {
      $("#userprofile").modal("hide");
    }

    const isUserStatus = computed(() => {
      if (state.activeUser) {
        if (
          (state.activeUser.level === 1 || state.activeUser.level === 2) &&
          !state.projectDetail.deleted_at
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    const smartContractAccess = computed(() => {
      if (state.projectDetail && state.projectDetail.smart_contract && state.projectDetail.smart_contract.role) {
        if (state.projectDetail.smart_contract.role === 'owner' || state.projectDetail.smart_contract.role === 'co-owner' || state.projectDetail.smart_contract.role === 'todo_payer' || state.projectDetail.smart_contract.role === 'todo_member') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
    function toggleTourGuide() {
      if (store.state.showTourGuide && isUserStatus.value === true) {
        introJs()
          .setOptions({
            exitOnOverlayClick: true,
            steps: [
              {
                title: "Welcome",
                intro: `Welcome to the Project: ${state.projectDetail.name}`,
              },
              {
                title: "Create Channel",
                element: document.getElementById(
                  "createChannel"
                ) as HTMLInputElement,
                intro: "Create Channel to collaborate and chat.",
                // position: 'left'
                // position: 'bottom',
                position: "top",
                // position: 'right',
              },
              {
                title: "History",
                element: document.getElementById(
                  "historyDetails"
                ) as HTMLInputElement,
                intro: "Activitity log is shown here.",
                // position: 'left'
                // position: 'bottom',
                position: "top",
                // position: 'right',
              },
              {
                title: "Chat Channels",
                element: document.getElementById(
                  "chatChannel"
                ) as HTMLInputElement,
                intro:
                  "Chat Channels, add members in chat channel and its Details.",
                // position: 'left'
                // position: 'bottom',
                position: "top",
                // position: 'right',
              },
              {
                title: "Members",
                element: document.getElementById(
                  "membersActivity"
                ) as HTMLInputElement,
                intro: "Add & Remove Users, Change Roles.",
                position: "left",
                // position: 'bottom',
                // position: 'top',
                // position: 'right',
              },
              {
                title: "Add Member",
                element: document.getElementById(
                  "addChannelMember"
                ) as HTMLInputElement,
                intro: "Add Members to Individual Channels.",
                // position: 'left'
                // position: 'bottom',
                position: "top",
                // position: 'right',
              },
              {
                title: "Latest Forms",
                element: document.getElementById(
                  "latestForms"
                ) as HTMLInputElement,
                intro: "Share Latest forms.",
                position: "left",
                // position: 'bottom',
                // position: 'top',
                // position: 'right',
              },
              {
                title: "Search Forms",
                element: document.getElementById(
                  "latestFormsSearch"
                ) as HTMLInputElement,
                intro: "Search the required forms.",
                position: "left",
                // position: 'bottom',
                // position: 'top',
                // position: 'right',
              },
            ],
          })
          .start();
      } else {
        introJs()
          .setOptions({
            exitOnOverlayClick: true,
            steps: [
              {
                title: "Welcome",
                intro: `Welcome to the Project: ${state.projectDetail.name}`,
              },
              {
                title: "History",
                element: document.getElementById(
                  "historyDetails"
                ) as HTMLInputElement,
                intro: "Activitity log is shown here.",
                // position: 'left'
                // position: 'bottom',
                position: "top",
                // position: 'right',
              },
              {
                title: "Chat Channels",
                element: document.getElementById(
                  "chatChannel"
                ) as HTMLInputElement,
                intro:
                  "Chat Channels, add members in chat channel and its Details.",
                // position: 'left'
                // position: 'bottom',
                position: "top",
                // position: 'right',
              },
              {
                title: "Members",
                element: document.getElementById(
                  "membersActivity"
                ) as HTMLInputElement,
                intro: "Add & Remove Users, Change Roles.",
                position: "left",
                // position: 'bottom',
                // position: 'top',
                // position: 'right',
              },
              {
                title: "Latest Forms",
                element: document.getElementById(
                  "latestForms"
                ) as HTMLInputElement,
                intro: "Share Latest forms.",
                position: "left",
                // position: 'bottom',
                // position: 'top',
                // position: 'right',
              },
              {
                title: "Search Forms",
                element: document.getElementById(
                  "latestFormsSearch"
                ) as HTMLInputElement,
                intro: "Search the required forms.",
                position: "left",
                // position: 'bottom',
                // position: 'top',
                // position: 'right',
              },
            ],
          })
          .start();
      }
    }
    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          // console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );
    function redirectToPhaseChat(phase: any) {
      router.push({
        name: "admin-channel",
        params: { channelId: phase.channel.uuid.toString() },
      });
    }
    function hideCreateToDoModal() {
      state.data.todo.payerEmail = "";
      state.ui.errors.hasError = false;
      state.ui.errors.errorList.createToDo = [];
      $("#createToDo").modal("hide");
    }
    function showCreateToDoModal() {
      // hideCreateToDoModal();
      state.data.todo.payerEmail = "";
      state.ui.errors.hasError = false;
      state.ui.errors.errorList.createToDo = [];
      $("#createToDo").modal("show");
    }
    function createProjectToDoValidation() {
      console.log("*** TODO VALIDATION  ***");
      state.ui.errors.hasError = false;
      state.ui.errors.errorList.createToDo = [];
      if (!state.data.todo.payerEmail) {
        state.ui.errors.errorList.createToDo.push("Email address is required.");
      } else if (!validationService.isValidEmail(state.data.todo.payerEmail)) {
        state.ui.errors.errorList.createToDo.push(
          "Email address is not valid."
        );
      }
      if (
        !state.ui.errors.errorList.createToDo &&
        !state.ui.errors.errorList.createToDo.length
      ) {
        state.ui.errors.hasError = false;
      } else if (state.ui.errors.errorList.createToDo.length != 0) {
        state.ui.errors.hasError = true;
      }
    }
    function createProjectToDo() {
      if (state.ui.isPostLoading.createProjectToDo) return false;
      createProjectToDoValidation();
      if (state.ui.errors.hasError || state.errorList.length != 0) return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        payer_email: common.lowerCase(state.data.todo.payerEmail),
      };
      console.log("CHECK PAYLOAD >>>>** ", payLoad);
      // memberSelected.resend = true;
      state.ui.isPostLoading.createProjectToDo = true;
      ethitransService
        .projectSetToDo(payLoad)
        .then((res: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.projectDetail.is_todo = 1;
          state.projectDetail.todo.status = 2;
          // console.log("createProjectToDo:>>> ", res.data.data);
          hideCreateToDoModal();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage =
            "Todo Created with Payer's Email Successfully.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.createToDo)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.createToDo.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.createProjectToDo = false;
        });
    }
    function showEditChannelModal(channel: any) {
      console.log("Channel >>>", channel);
      state.data.selectedChannel = JSON.parse(JSON.stringify(channel));
      state.data.selectedEditChannel.name = state.data.selectedChannel.name;
      state.data.selectedEditChannel.description =
        state.data.selectedChannel.description;
      $("#editChannelModal").modal("show");
    }
    function hideEditChannelModal() {
      state.data.selectedChannel = {};
      $("#editChannelModal").modal("hide");
    }
    function saveEditChannel() {
      console.log("save edit channel");
      state.ui.errors.errorList.saveEditChannel = [];
      state.ui.errors.hasError = false;
      // console.log("CHECK THE SELECTED >>> ", memberSelected);
      if (state.ui.isPostLoading.saveEditChannel) return false;

      const payLoad = {
        id: state.data.selectedChannel.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.data.selectedChannel.project_id,
        name: state.data.selectedEditChannel.name,
        description: state.data.selectedEditChannel.description,
      };
      // memberSelected.resend = true;
      state.ui.isPostLoading.saveEditChannel = true;
      ethitransService
        .channelUpdate(payLoad)
        .then((res: any) => {
          state.channelList.forEach((item: any) => {
            if (item.uuid === res.data.data.uuid) {
              item.name = res.data.data.name;
              item.description = res.data.data.description;
            }
          });
          state.data.privateChatChannel.forEach((item: any) => {
            if (item.uuid === res.data.data.uuid) {
              item.name = res.data.data.name;
              item.description = res.data.data.description;
            }
          });
          hideEditChannelModal();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Edited Successfully.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.saveEditChannel)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.saveEditChannel.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // memberSelected.resend = false;
          state.ui.isPostLoading.saveEditChannel = false;
          // state.isLoading.getProjectRoleList = false;
        });
    }
    function hidePendingMemberModal() {
      $("#pendingmemberModal").modal("hide");
    }
    function hideMemberModal() {
      $("#memberModal").modal("hide");
    }
    function showMemberList(list: any) {
      // console.log("Show List showMemberList >>> ", list)
      state.data.memberList = [];
      if (list) {
        state.data.memberList = list;
        $("#memberModal").modal("show");
      }
    }
    function showPendingList(channelSelected: any) {
      // if(isUserStatus && channelSelected.status.code === 'active')
      // console.log("CHANNEL INDEX >>", common.findIndex(state.channelList, channelSelected))
      state.data.channelIndex = common.findIndex(
        state.channelList,
        channelSelected
      );
      // console.log("CHANNEL INDEX >>", state.data.channelIndex);
      const list = channelSelected.pending_members;
      // console.log("Show List showPendingList >>> ", list)
      state.data.pendingList = [];
      if (list) {
        list.forEach((item: any) => {
          item.resend = false;
          item.delete = false;
          item.statusCode = channelSelected.status.code;
        });
        state.data.pendingList = list;
        $("#pendingmemberModal").modal("show");
      }
    }
    function backInvitation() {
      state.data.invitationVerify.showStatus =
        !state.data.invitationVerify.showStatus;
    }
    function backProjectInvitation() {
      state.data.invitationProjectVerify.showStatus = false;
    }

    function hideResendInvitationConfirmation() {
      $("#resendinvitationpendingmember").modal("hide");
    }
    function showResendInvitationConfirmation(memberSelected: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = memberSelected;
      $("#resendinvitationpendingmember").modal("show");
    }
    function deletePublicMember(item: any) {
      state.inviteAsPublic = state.inviteAsPublic.filter((e: any) => e != item);
    }
    function resendInvitation() {
      const memberSelected: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("CHECK THE SELECTED >>> ", memberSelected);

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_id: memberSelected.uuid,
      };
      // memberSelected.resend = true;
      state.ui.isPostLoading.resendInvitation = true;
      ethitransService
        .invitationResend(payLoad)
        .then((res: any) => {
          // console.log("invitationResend:>>> ", res.data.data);
          hideResendInvitationConfirmation();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Invitation Resend Successfully.";
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // memberSelected.resend = false;
          state.ui.isPostLoading.resendInvitation = false;
          // state.isLoading.getProjectRoleList = false;
        });
    }
    function getProjectMemberRoleList() {
      state.isLoading.getProjectRoleList = true;
      ethitransService
        .projectRoleList()
        .then((res: any) => {
          // console.log("<<<:ROLE MODIFICATION:>>> ", res.data.data);
          const filteredRoles: any = [];
          if (state.activeMember.level === 1) {
            res.data.data.forEach((item: any) => {
              // if(item.level != state.activeMember.level && item.level != 1){
              filteredRoles.push(item);
              // }
            });

            // console.log("FINAL ROLES", filteredRoles);
          } else {
            res.data.data.forEach((item: any) => {
              if (item.level != state.activeMember.level && item.level != 1) {
                filteredRoles.push(item);
              }
            });
            // console.log("FINAL ROLES", filteredRoles);
          }
          state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getProjectRoleList = false;
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function changeRole(member: any) {
      $("#userprofile").modal("show");
      getProjectMemberRoleList();
      console.log("Selected Member WOOOOO:>>>", member.project_role.id);
      // state.selectedMember = {};
      state.selectedMember = member;
      state.allCreatedChannel = state.channelList.map((item: any) => {
        if (item.active_member.some((data: any) => data.user.email === member.user.email)) {
          return { ...item, isChecked: true };
        } else {
          return { ...item, isChecked: false };
        }
      });

      console.log("Selected Member WOOOOO:>>>", state.allCreatedChannel);

      state.selectedMemberRole = member.project_role.id;
      state.data.selectedValidityDate = new Date(
        `${state.selectedMember.validity_date}`
      );
    }

    function hideChangeRole() {
      state.checkActivity = false;
      $("#userprofile").modal("hide");
    }

    function hideProjectMemberRemove() {
      $("#removemember").modal("hide");
      state.selectedMemberForRemoval = {};
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
    }

    function selectForMemberRemove(member: any) {
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      $("#removemember").modal("show");
      // console.log("Member Details:>> ", member);
      state.selectedMemberForRemoval = {};
      state.selectedMemberForRemoval = member;
    }

    function hideShareProjectRemove() {
      $("#removeProjectShareInvitation").modal("hide");
      state.selectedShareInvitationForRemoval = {};
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
    }

    function selectShareProjectRemove(member: any) {
      console.log('aler', member);
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      $("#removeProjectShareInvitation").modal("show");
      state.selectedShareInvitationForRemoval = {};
      state.selectedShareInvitationForRemoval = member;
    }

    function callBackMembers() {
      // console.log("HELLO CALL BACK MEMBERS");
    }

    function changeExistingMember() {
      // console.log("CHECK MSG", state.existingMember);
      state.invite.users.push(state.existingMember);
      // console.log("CHECK MSG AFTER", state.invite.users);
      state.existingMember = "";
    }

    function checkAllPhases(phases: any) {
      state.data.selectSharePhase = [];
      phases.forEach((phase: any) => {
        state.data.selectSharePhase.push(phase.uuid);
      });
    }



    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      state.invite.invites = state.invite.invites.filter((e: any) => e.email !== item.email && e.phone !== item.phone);
      // insert if the length gets to zero

      if (state.invite.invites && state.invite.invites.length === 0) {
        state.invite.invites.push({
          'email': "",
          'first_name': '',
          'last_name': '',
          'phone': '',
          'mailNotification': false,
          'smsNotification': false,
          'mailDisable': false,
        });
      }
      // allUsers.value.push(item);

      const setRow: any = {};
      setRow.label = item.email
      setRow.email = item.email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = item.full_name = item.first_name + " " + item.last_name;
      setRow.phone = item.phone
      // allUsers.value.push(item);
      allUsers.value.push(setRow);

    }

    function removeSelectedChannel(item: any) {
      state.selectedAllChannel = state.selectedAllChannel.filter((e: any) => e != item);
      allChannels.value.push(item);
    }

    function changeInviteMember() {
      state.inputError = false;
      state.duplicate = false;
      state.invite.invites.forEach((invite: any) => {
        if (invite.first_name == '') {
          invite.fNameError = true;
        } else {
          invite.fNameError = false;
        }
        if (invite.last_name == '') {
          invite.lNameError = true;
        } else {
          invite.lNameError = false;
        }
        if ((invite.smsNotification && !invite.phone) || (invite.phone && invite.phone.length != 11)) {
          invite.phoneError = true;
        } else {
          invite.phoneError = false;
        }
      })
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.inputError = state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '' || item.phoneError);
      state.duplicate = state.invite.invites.some((item: any) => item.email === state.inviteMember);
      if (state.inputError || state.duplicate) return;
      // console.log("CHECK MSG", state.inviteMember);

      if (validationService.isValidEmail(state.inviteMember)) {
        // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (state.invite.invites.length === 0) {
          state.invite.invites.push({
            'email': state.inviteMember,
            'first_name': '',
            'last_name': '',
            'phone': '',
            'mailNotification': true,
            'smsNotification': false,
            'mailDisable': false,
          });
        } else {
          if (state.invite.invites.filter((item: any) => item.email != state.inviteMember)) {
            state.invite.invites.push({
              'email': state.inviteMember,
              'first_name': '',
              'last_name': '',
              'phone': '',
              'mailNotification': true,
              'smsNotification': false,
              'mailDisable': false,
            });
          }

        }
        state.invite.invites = state.invite.invites.filter(
          (item, i, ar) => ar.indexOf(item) === i
        );
        state.inviteMember = "";
      }
    }
    function validationPublicUser() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.selectedAllChannel.length) {
        state.errorList.push("Channel is required.");
      }

      if (!state.inviteAsPublic && !state.inviteAsPublic.length) {
        state.errorList.push("At least 1 user is required.");
      } else {
        state.inviteAsPublic.forEach((item: any) => {
          if (item.email && !validationService.isValidEmail(item.email)) {
            state.errorList.push("Invalid email.");
          }
          if (!item.email) {
            state.errorList.push("Email must be required.");
          }
          //  if (!item.name) {
          //   state.errorList.push("Name must be required.");
          // }
          if (item.smsNotification) {
            if (!item.phone) {
              state.errorList.push("Mobile number must be required.");
              state.isErrorStatus = true;
            } else if (item.phone.length != 11) {
              state.errorList.push("Mobile Number must be of 10 digit.");
              state.isErrorStatus = true;
            }
          }
          //  if(!item.smsNotification && !item.emailNotification) {
          //   state.errorList.push("Email or SMS one method must be required.");
          // }
        });
      }
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }

    function validationInviteSharePhase() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.users.length && !state.invite.invites.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      if (state.data.selectSharePhase.length == 0) {
        state.errorList.push("At least 1 phase is required.");
      }
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }


    function validationInviteSharePhaseUpdate() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (state.data.invitationProjectEdit.editPhase.length == 0) {
        state.errorList.push("At least 1 phase is required.");
      }
      if (!state.data.invitationProjectEdit.editId) {
        state.errorList.push("edit id is required.");
      }
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

    }

    function hideEditShareProject() {
      state.data.invitationProjectEdit.editPhase = [];
      $("#editShareProject").modal("hide");
      state.data.selectSharePhase = [];
      state.phaseOptions = [];
      $("#editShareProject").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    function getAccountFormList() {
      state.ui.errors.errorList.errorList = [];
      state.ui.errors.hasError = true;
      const payLoad = {
        search: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.searchBy,
        },
        filter: {
          type: state.filter.type,
          email: localStorage.getItem("current_user_details"),
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_date: state.filter.sortBy,
          // "owner.created_by.name": localStorage.getItem("current_user_details"),
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: 10,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: 1,
        },
      };
      // state.ui.postLoading.getAccountFormList = true;
      ethitransService
        .projectAccountFormListSearch(payLoad)
        .then((res: any) => {
          // console.log(
          //   "WOWO projectAccountFormListSearch Response : >>> ",
          //   res.data
          // );
          state.templateList = res.data.docs ? res.data.docs : [];
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.errorList)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.errorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.ui.postLoading.getAccountFormList = false;
        });
    }
    function showCreateProjectChannel() {
      if (state.data.subscriptionDetails.channel_per_project === state.channelList.length) {
        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.isIcludeCoOwner = false;
        state.coOwner = state.memberList.filter((item: any) => item.project_role.code == 'co-owner')
          .map((item: any) => ({ ...item, isChecked: false }));
        $("#inviteMember").modal("hide");
        $("#createChannelByProject").modal("show");
      }
    }

    function getProjectDetail() {
      state.isLoading.getProjectDetail = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
      };
      ethitransService
        .projectDetails(payLoad)
        .then((res: any) => {
          state.data.subscriptionDetails = res.data.data.package_features;
          state.data.projectRole = res.data.data.project_role.code;
          state.todoData = res.data.data.todo;
          // convertedFileSize
          if (res.data.data.approval) {
            // console.log("Project Details Response Check:>>> ", res.data.data.approval);
            if (res.data.data.approval && res.data.data.approval.attachments) {
              res.data.data.approval.attachments.forEach((attachment: any) => {
                // console.log("Attachment Check:>>> ", attachment);
                attachment.activeLink =
                  config.liveImageBaseUrl + attachment.file_link;
                attachment.convertedFileSize = common.formatBytes(
                  parseInt(attachment.file_size)
                );
              });
            }
          }
          if (res.data.data && res.data.data.todo && res.data.data.todo.phases && res.data.data.todo.phases.data) {
            res.data.data.todo.phases.data.forEach((item: any) => {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.end_date = common.localDateOnly(item.end_date);
            });
          }


          state.projectDetail = res.data.data ? res.data.data : {};
          state.data.shareProjectMember = res.data.data.project_share ? res.data.data.project_share : [];

          if (res.data.data.street_address) {
            state.projectDetail.wholeAddress = `${res.data.data.street_address} ${res.data.data.city}`;
          }
          if (res.data.data.city) {
            state.projectDetail.wholeAddress = `${res.data.data.city}`;
          }
          if (res.data.data.state) {
            state.projectDetail.wholeAddress = `${res.data.data.state}`;
          }
          if (res.data.data.country) {
            state.projectDetail.wholeAddress = `${res.data.data.country}`;
          }
          if (res.data.data.city && res.data.data.state) {
            state.projectDetail.wholeAddress = `${res.data.data.city}, ${res.data.data.state}`;
          }
          if (res.data.data.city && res.data.data.country) {
            state.projectDetail.wholeAddress = `${res.data.data.city}, ${res.data.data.country}`;
          }
          if (res.data.data.state && res.data.data.country) {
            state.projectDetail.wholeAddress = `${res.data.data.state}, ${res.data.data.country}`;
          }
          if (
            res.data.data.city &&
            res.data.data.state &&
            res.data.data.country
          ) {
            state.projectDetail.wholeAddress = `${res.data.data.city}, ${res.data.data.state}, ${res.data.data.country}`;
          }
          // console.log("Project user", res.data.data.project_role);
          // console.log("Project user's Level", res.data.data.project_role.code);
          // if(res.data.data.project_role.level )
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          res.data.data.all_members.forEach((member: any) => {
            member.isChangeRole = false;
          });
          // console.log("ADMIN CHECK", admin);
          if (admin && admin.level && admin.code) {
            res.data.data.all_members.forEach((member: any) => {
              member.isLoading = false;
              if (admin.level != member.project_role.level) {
                if (member.project_role.level > admin.level) {
                  // console.log("INDIDE ADMIN", admin.level);
                  // console.log("INDIDE MEMBER", member.project_role.level);
                  member.isChangeRole = true;
                }
              }
            });
          }

          state.memberList = res.data.data.all_members
            ? res.data.data.all_members
            : [];
          state.activeMemberList = state.memberList.filter((item: any) => {
            return item.deleted_at === null;
          });

          state.pendingList = res.data.data.pending_members
            ? res.data.data.pending_members
            : [];
          state.activeUser = res.data.data.project_role
            ? res.data.data.project_role
            : {};
          state.activityList = res.data.data.activities
            ? res.data.data.activities
            : [];
          // state.templateList = res.data.data.templates
          //   ? res.data.data.templates
          //   : [];
          // showPendingList
          state.channelList = res.data.data.channels
            ? res.data.data.channels
            : [];
          state.data.privateChatChannel = res.data.data.private_channels
            ? res.data.data.private_channels
            : [];
          state.data.estimationChatChannel = res.data.data.estimation_channels
            ? res.data.data.estimation_channels
            : [];
          state.data.phaseChatChannel = res.data.data.phase_channels
            ? res.data.data.phase_channels
            : [];
          state.data.approvalChatChannel = res.data.data.payer_channels
            ? res.data.data.payer_channels
            : [];
          getAccountFormList();
          state.ui.isLoading.projectDetailsAPICalled = true;
          state.data.allProject = res.data.data.allProject;
          state.data.allChannel = res.data.data.allChannel;
          state.data.allMember = res.data.data.allMember;

          if (state.data.allProject > 0 && state.data.allChannel == 0) {
            setTimeout(() => {
              showTour('createChannel', 'Create Channel', 'Welcome Onboard! Proceed with creating a Channel!');
            }, 100);
          } else if (state.data.allProject > 0 && state.data.allChannel > 0 && state.data.allMember == 0) {
            setTimeout(() => {
              showTour('addMember', 'Add Member', 'Get started with your project by adding new member(s)');
            }, 100);
          }
          if (route.query.channel === 'true') {
            showCreateProjectChannel();
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getProjectDetail = false;
          router.replace({ query: {} });
        });
    }

    function getProjectChannel() {
      state.isLoading.getProjectChannel = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const project_id = projectId;
      ethitransService
        .getProjectChannel(project_id, state.data.search, state.data.channelStatus)
        .then((res: any) => {
          state.channelList = res.data.data.channels
            ? res.data.data.channels
            : [];
          // state.allCreatedChannel = res.data.data.channels
          //   ? res.data.data.channels
          //   : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getProjectChannel = false;
          state.isLoading.searchProjectChannel = false;
        });
    }
    function dropDownChannelList() {
      channelLists.value = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      const project_id = projectId;
      ethitransService
        .getProjectChannel(project_id, '', state.data.channelStatus)
        .then((res: any) => {
          res.data.data.channels.forEach((channel: any) => {
            channelLists.value.push({
              'id': channel.id,
              'label': channel.name,
              'name': channel.name,
            })
          })
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.isLoading.searchProjectChannel = false;
        });
    }
    function changeChannelStatus() {
      channelListSelected.value = [];
      state.data.search = '';
      state.isLoading.searchProjectChannel = true;
      dropDownChannelList();
      getProjectChannel();
    }

    function searchChannel(data: any) {
      state.data.search = data.name;
      state.isLoading.searchProjectChannel = true;
      getProjectChannel();

    }
    function searchChannelRemove() {
      state.data.search = '';
      channelListSelected.value = [];
      state.isLoading.searchProjectChannel = true;
      getProjectChannel();

    }
    function invitationShareProjectPhaseUpdate() {

      validationInviteSharePhaseUpdate();
      if (state.errorList.length != 0 || state.isErrorStatus || state.postLoading.inviteProjectUsers) return false;
      const payLoad = {
        id: state.data.invitationProjectEdit.editId,
        phases: state.data.invitationProjectEdit.editPhase,
        individual: state.data.invitationProjectEdit.isIndividual,
      };
      ethitransService
        .inviteProjectShareUpdate(payLoad)
        .then((res: any) => {
          hideEditShareProject();
          state.data.showSuccesCustomModal = true;
          state.data.invitationProjectEdit.editId = '';
          state.data.invitationProjectEdit.editPhase = [];
          state.data.successFulMessage = "Update sucessfully";
          getProjectDetail();
          state.phaseOptions = [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading.inviteProjectUsers = false;
          state.data.inviteAsPublicUser = false;
        });
    }

    function changeInvitePublicMember() {
      validationPublicUser();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      state.inviteAsPublic.push({
        name: "",
        email: "",
        phone: "",
        smsNotification: false,
        mailNotification: true,
      });
    }


    function removeSelectedUser(item: any) {
      // console.log("CHECK INVITATION >>>>", state.invite.invites.length);
      if (state.invite.invites.length === 0) return false;
      state.invite.invites = state.invite.invites.filter((e: any) => e != item);
      // console.log("AFTER DELECTION", state.invite.invites);
      // reset the invite existing member
      state.invite.users = state.invite.users.filter((e: any) => e.email !== item.email && e.phone !== item.phone);

      const setRow: any = {};
      setRow.label = item.email
      setRow.email = item.email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = item.full_name = item.first_name + " " + item.last_name;
      setRow.phone = item.phone
      // allUsers.value.push(item);
      allUsers.value.push(setRow);
      // allUsers.value.push(item);
    }



    // Method to handle filtered objects
    function handleFilteredObjects(filteredObjects: any, selectedRow: any) {
      console.log('Filtered objects:', selectedRow);
      const userSelected: any = JSON.parse(JSON.stringify(selectedRow));
      console.log('Filtered objects:', filteredObjects);
      state.invite.users = state.invite.users.filter((e: any) => e.email !== userSelected.previousEmail && e.phone !== userSelected.previousPhone);
      if (!userSelected.phone) {
        userSelected.phone = userSelected.previousPhone
      }
      if (!userSelected.email) {
        userSelected.email = userSelected.previousEmail
      }


      const row: any = JSON.parse(JSON.stringify(common.filterAndTransformUsers(state.userList, userSelected.previousPhone, userSelected.previousEmail)))
      // eslint-disable-next-line @typescript-eslint/camelcase
      row[0].full_name = row[0].first_name + " " + row[0].last_name
      row[0].email = row[0].previousEmail;
      row[0].phone = row[0].previousPhone;


      const setRow: any = {};
      setRow.label = row[0].email
      setRow.email = row[0].email
      // eslint-disable-next-line @typescript-eslint/camelcase
      setRow.full_name = row[0].full_name;
      setRow.phone = row[0].phone
      console.log("Filtered Row >>> ", setRow);
      allUsers.value.push(setRow);
      // Add your logic here to handle these objects
      // alert("IN HERE and Activate Delete")
    }
    const checkEmptyFields = (index: any, key: any) => {
      console.log("select row while deleting")
      if (state.invite.invites[index][key] === "") {
        if ((!state.invite.invites[index].key && state.invite.invites[index].previousPhone) || (!state.invite.invites[index].key && state.invite.invites[index].previousEmail)) {
          let itemForDelete: any = {};
          itemForDelete = state.invite.invites[index];
          handleFilteredObjects(`${key} Is not available`, itemForDelete);
        }
      }
    };
    function projectMemberListByChannel() {
      if (
        state.data.selectedChannelByProject.channel &&
        !state.data.selectedChannelByProject.channel.uuid
      )
        return false;
      // function projectMemberListByChannel() {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.selectedChannelByProject.channel.uuid,
      };
      state.ui.isPostLoading.projectMemberListByChannel = true;
      ethitransService
        .projectMemberListByChannel(payLoad)
        .then((res: any) => {
          console.log("<<< Prabhu Check:>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];

          allUsers.value = [
            {
              'id': 'convo101',
              'label': 'Select All user',
              'email': 'Select All user',
              'full_name': 'Select All user'
            }
          ];
          res.data.data.forEach((user: any) => {
            console.log("Check the User for Email or not")
            if (!validationService.isValidEmail(user.email)) {
              user.email = null;
            }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (!user.mobile_number) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              user.mobile_number = validationService.validateMobileNumber(user.contact_number) ? user.contact_number : null;
            }
            allUsers.value.push({
              'id': user.uuid,
              'label': user.email,
              'email': user.email,
              'full_name': user.full_name,
              'phone': user.mobile_number,
            })
          })
          console.log("<<< Prabhu Check After :>>> ", res.data.data);

          // state.data.channelList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.projectMemberListByChannel = false;
        });
    }
    // eslint-disable-next-line @typescript-eslint/camelcase
    const nameWithEmail = ({ full_name, email, phone }: any) => {
      if (email) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return full_name ? `${full_name} — [${email}]` : '';
      } else {

        // eslint-disable-next-line @typescript-eslint/camelcase
        return full_name ? `${full_name} — [${phone}]` : '';
      }
    };
    function selectToChange(value: any) {
      if (value && !value.id) return false;
      // console.log("CHANNEL >>> ", value)
      // alert(value.id);
      const existingIndex = state.selectedAllChannel.some((obj: any) => obj.id === value.id);
      if (value.id == "convo101" && allChannels.value.length >= 2) {
        const filteredArray = allChannels.value.filter((item: any) => item.id !== "convo101");
        state.selectedAllChannel = state.selectedAllChannel.concat(filteredArray);
        allChannels.value = [
          {
            'id': 'convo101',
            'label': 'Select All Channels',
            'name': 'Select All Channels',
          }
        ];
      }
      else if (!existingIndex && value.id != "convo101") {
        state.selectedAllChannel.push(value);
        const filteredArray = allChannels.value.filter((item: any) => item.id !== value.id);
        allChannels.value = filteredArray;
      }
      selectedChannels.value = {};
    }
    function getObjectById(channels: any, id: any) {
      return channels.find((channel: any) => channel.id === id) || null;
    }
    async function getChannelByProject(
      channelStatus: boolean,
      projectUUID: any
    ) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectUUID,
      };
      state.ui.isPostLoading.getChannelByProject = true;
      await ethitransService
        .getChannelListByProject(payLoad)
        .then((res: any) => {
          console.log("getChannelByProject Response Check:>>> ", res.data.data);
          console.log("Length Check >>> ", res.data.data.length)
          // if(!res.data.data.length) return false;
          // alert("HI")
          allChannels.value = [
            {
              'id': 'convo101',
              'label': 'Select All Channels',
              'name': 'Select All Channels',
            }
          ];
          state.data.channelList = res.data.data ? res.data.data : [];
          res.data.data.forEach((channel: any) => {
            if (channel.type != 4) {
              allChannels.value.push({
                'id': channel.uuid,
                'label': channel.name,
                'name': channel.name,
              })
            }

          })
          if (cookie.getCookie('channelList')) {
            const channels = cookie.getCookie('channelList');
            // console.log("HI HELLO", channels);
            // console.log("retuired Object", getObjectById(allChannels.value, channels));
            selectToChange(getObjectById(allChannels.value, channels));
          }
          if (state.data.channelList && state.data.channelList.length) {
            if (!state.data.channelList && !state.data.channelList.length)
              return false;
            if (!channelStatus) {
              state.data.selectedChannelByProject.channel =
                state.data.channelList[state.data.channelList.length - 1];
              // console.log("IF THE CHANNEL SELECTED >>>", state.data.selectedChannelByProject.channel)
            }
            //  else {
            //   const data = state.data.channelList.filter(
            //     (e: any) =>
            //       e.uuid === state.data.selectedChannelByProject.channel.uuid
            //   );
            //   // state.data.selectedChannelByProject.channel = data[0];
            //   // console.log("ELSE CHANNEL DIRECT SELECT >>>", state.data.selectedChannelByProject.channel)
            // }
            projectMemberListByChannel();
            state.showCheckChannelMessage = true;
          } else {
            state.showCheckChannelMessage = false;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getChannelByProject = false;
        });
    }
    function hideSuccessInvitationModal() {
      $("#successModal").modal("hide");
      cookie.removeCookie('channelList');
    }


    function addMember(channel: any) {
      console.log("ALL CHANNELS >>>", allChannels.value)
      // alert("HI PROBHU");
      // Outputs the value of the 'theme' cookie

      state.data.showSuccesCustomModal = false;
      state.ui.modal.selectedFromChannel = false;
      if (channel.uuid) {
        state.ui.modal.selectedFromChannel = true;
        state.selectedAllChannel.push({
          'id': channel.uuid,
          'label': channel.name,
          'name': channel.name,
        });
      } else {
        state.selectedAllChannel = [];
      }
      state.data.inviteAsGuest = false;
      state.showCheckChannelMessage = true;
      state.data.invitationVerify.showStatus = false;
      state.data.selectedChannelByProject.channel = {};
      state.data.invitationVerifyMsg = "";
      state.data.invitationVerify.newMemberList = [];
      state.data.invitationVerify.registeredMemberList = [];
      state.data.invitationVerify.pendingMemberList = [];
      state.inviteAsPublic = [];
      $("#successModal").modal("hide");
      state.userList = [];
      // alert("Add New Members");
      selectedValue.value = 2;
      selectedDateForMember.value = new Date();



      $("#inviteMember").modal("show");
      $("#inviteMember").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
      // state.invite.users.push({ email: ''}) ;
      state.data.invitationVerifyMsg = "";
      state.existingMember = "";
      state.inviteMember = "";
      state.invite.users = [];
      state.invite.invites = [];
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.errorList = [];
      state.isErrorStatus = false;
      if (state.invite.invites.length === 0) {
        // inject the empty block
        state.invite.invites.push({
          'email': "",
          'first_name': '',
          'last_name': '',
          'phone': '',
          'mailNotification': false,
          'smsNotification': false,
          'mailDisable': false,
        });
      }
      if (channel.uuid) {
        // console.log("Chennel >>>", channel)
        state.data.selectedChannelByProject.channel;
        state.data.selectedChannelByProject.channel = channel;
        getChannelByProject(true, projectId);
      } else {
        state.data.selectedChannelByProject.channel = {};
        getChannelByProject(false, projectId);
      }

      // console.log("SHOW DATA", state.invite.users);
    }

    function hideAddMember() {
      state.selectedAllChannel = [];
      $("#inviteMember").modal("hide");
      state.data.inviteAsPublicUser = false;
      $("#inviteMember").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    function getShareProjectPhaseList() {
      ethitransService
        .shareProjectPhaseList(projectId.toString())
        .then((res: any) => {
          console.log("name", res.data.data.phases);
          state.shareProjectUserList = res.data.data.members ? res.data.data.members : [];
          state.phaseOptions = [];
          res.data.data.phases.forEach((item: any) => {
            state.phaseTask = []
            item.child_phases.forEach((item2: any) => {
              const childData = [] as any;
              childData['id'] = item2.uuid;
              childData['label'] = item2.name;
              state.phaseTask.push(childData)
            });
            state.phaseOptions.push({
              'id': item.uuid,
              'label': item.name,
              'children': state.phaseTask,
            })
          })
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          console.log("array", state.phaseOptions);
          state.isLoading.getUserList = false;
        });
    }

    function addShareProject(channel: any) {
      // console.log("SHOW DATA", state.invite.users);


      state.data.selectSharePhase = [];
      state.phaseOptions = [];
      $("#successProjectShareInvitationModal").modal("hide");
      state.userList = [];
      // alert("Add New Members");
      $("#addShareProject").modal("show");
      $("#addShareProject").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
      // state.invite.users.push({ email: ''}) ;
      state.data.invitationVerifyMsg = "";
      state.existingMember = "";
      state.inviteMember = "";
      state.invite.users = [];
      state.invite.invites = [];
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.errorList = [];
      state.isErrorStatus = false;
      if (channel.uuid) {
        // console.log("Chennel >>>", channel)
        state.data.selectedChannelByProject.channel;
        state.data.selectedChannelByProject.channel = channel;
        getChannelByProject(true, projectId);
      } else {
        state.data.selectedChannelByProject.channel = {};
        getChannelByProject(false, projectId);
      }
      getShareProjectPhaseList();


    }

    function hideaddShareProject() {
      state.data.selectSharePhase = [];
      state.phaseOptions = [];
      $("#addShareProject").modal("hide");
      $("#addShareProject").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    function projectShareInvitationEdit(inviteId: any) {
      ethitransService
        .projectShareMemberInvitationEdit(inviteId.toString())
        .then((res: any) => {
          console.log(res.data.data.individual)
          state.isLoading.editProjectShare = true;
          state.data.invitationProjectEdit.isIndividual = res.data.data.individual === 1 ? true : false;
          state.data.invitationProjectEdit.email = res.data.data.email;
          state.data.invitationProjectEdit.editPhase = [];
          res.data.data.phases.forEach((phase: any) => {
            phase.child_phases.forEach((task: any) => {
              if (task.checked == 1) {
                state.data.invitationProjectEdit.editPhase.push(task.uuid);
              }
            })

          });

          if (state.data.invitationProjectEdit.editPhase.length > 0) {
            state.isLoading.editProjectShare = false;
          } else {
            state.isLoading.editProjectShare = false;

          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          console.log("editdata", state.data.selectSharePhase)
          // state.isLoading.getProjectDetail = false;
        });
    }

    function editShareProject(inviteId: any) {
      state.data.invitationProjectEdit.editPhase = [];
      state.data.invitationProjectEdit.editId = inviteId;
      projectShareInvitationEdit(inviteId);
      $("#successProjectShareInvitationModal").modal("hide");
      state.userList = [];
      // alert("Add New Members");
      $("#editShareProject").modal("show");
      $("#editShareProject").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
      state.data.selectSharePhase = [];
      state.phaseOptions = [];
      // state.invite.users.push({ email: ''}) ;
      state.errorList = [];
      state.isErrorStatus = false;
      getShareProjectPhaseList();
    }



    function getUserList() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
      };
      state.isLoading.getUserList = true;
      ethitransService
        .userList(payLoad)
        .then((res: any) => {
          // console.log("<<<<<<UserList Response Check:>>>>>>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getUserList = false;
        });
    }

    function showSuccessModal() {

      $("#successModal").modal("show");
      $("#successModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    function showProjectShareSuccessModal() {
      state.data.selectSharePhase = [];
      state.phaseOptions = [];
      $("#successProjectShareInvitationModal").modal("show");
      $("#successProjectShareInvitationModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    function showCreateChannel() {
      $("#createChannel").modal("show");
      // state.errorList = [];
      // state.isErrorStatus = false;
      // state.createChannel.name = "";
      // state.createChannel.description = "";
      // $("#createChannel").modal("show");
    }
    function addCreateChannel() {
      $("#inviteMember").modal("hide");
      showCreateChannel();
    }

    function hideCreateChannel() {
      state.createChannel.name = "";
      state.createChannel.description = "";
      $("#createChannelByProject").modal("hide");
    }
    function hidePendingMemberRemove() {
      $("#removependingmember").modal("hide");
    }
    function selectPendingMemberForDeletes(member: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = member;
      $("#removependingmember").modal("show");
    }
    function modifyUserConversationHistoryDate(member: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = member;
      $("#modifyHistoryBymember").modal("show");
    }
    function hideHistoryMember() {
      $("#modifyHistoryBymember").modal("hide");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function channelPendingMemberRemapping(
      mainArray: any,
      index: number,
      insertArray: any
    ) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      mainArray[index].pending_members = insertArray;
      // eslint-disable-next-line @typescript-eslint/camelcase
      mainArray[index].pending_members_count = insertArray.length;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function channelMemberCountRemapping(
      mainArray: any,
      index: number,
      insertArray: any
    ) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      mainArray[index].pending_members = insertArray;
      // eslint-disable-next-line @typescript-eslint/camelcase
      mainArray[index].members_count = insertArray.length;
    }
    function deletePendingInvitation() {
      const member: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("Delete Check >>> ", member)
      if (!member.uuid) return false;
      const payLoad = {
        uuid: member.uuid,
      };
      state.ui.isPostLoading.deletePendingInvitation = true;
      // member.delete = true;
      ethitransService
        .deletePendingInvitation(payLoad)
        .then((res: any) => {
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Pending Member Deleted Successfully.";
          hidePendingMemberRemove();
          // console.log(
          //   "WOWO projectAccountFormListSearch Response : >>> ",
          //   res.data
          // );
          // state.templateList = res.data.docs ? res.data.docs : [];
          // member.delete = false;
          state.data.pendingList = state.data.pendingList.filter(
            (e: any) => e != member
          );
          channelPendingMemberRemapping(
            state.channelList,
            state.data.channelIndex,
            state.data.pendingList
          );
          // eslint-disable-next-line @typescript-eslint/camelcase
          // state.channelList[state.data.channelIndex].pending_members = state.data.pendingList
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // state.channelList[state.data.channelIndex].pending_members_count = state.data.pendingList.length
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deletePendingInvitation = false;
          // member.delete = false;
          // state.ui.postLoading.getAccountFormList = false;
        });
    }


    function setAcknowledgedData(data: any) {
      // console.log("CHECK", data)
      // if (!data) return false;
      state.projectDetail.approval = data;
      state.projectDetail.todo.status = 2;
    }


    function setNewValidity() {
      const day = state.data.selectedValidityDate;
      const dayWrapper = moment(day);
      const dayString = dayWrapper.format("YYYY-MM-DD");
      // console.log("Selected Date >>> ", dayString)
      // console.log("Selected User MM >>> ", state.selectedMember)
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: state.selectedMember.user.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        validity_date: dayString,
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
      };
      // console.log("FINAL Payload", payLoad)
      state.ui.isPostLoading.setNewValidity = true;
      ethitransService
        .updateValidityDate(payLoad)
        .then((res: any) => {
          console.log(
            "guestUserList Response WOWO: >>> ",
            res.data.data.validity_date
          );
          getProjectDetail();
          hideUserProfile();
          // $("#userprofile").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.setNewValidity = false;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "User Role Updated Successfully.";
        });
    }

    function removeProjectRole() {
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      if (state.postLoading.removeProjectRole) return false;

      const payLoad = {
        id: state.selectedMemberForRemoval.id,
      };
      state.postLoading.removeProjectRole = true;
      ethitransService
        .removeProjectMember(payLoad)
        .then((res: any) => {

          state.memberList = res.data.data ? res.data.data : [];
          hideProjectMemberRemove();
          getProjectDetail();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Member Remove Successfully.";
          // hideProjectMemberRemove();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.removeProjectRole)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.removeProjectRole.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.postLoading.removeProjectRole = false;
        });
    }

    function removeShareInvitation() {
      state.ui.errors.errorList.removeProjectRole = [];
      state.ui.errors.hasError = false;
      if (state.postLoading.removeShareInvitation) return false;
      state.postLoading.removeShareInvitation = true;
      ethitransService
        .removeProjectShareInvitation(state.selectedShareInvitationForRemoval.id.toString())

        .then((res: any) => {
          hideShareProjectRemove();
          state.data.shareProjectMember = res.data.data.project_share ? res.data.data.project_share : [];
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Member Remove Successfully.";
          // hideProjectMemberRemove();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.removeProjectRole)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.removeProjectRole.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.postLoading.removeShareInvitation = false;
        });
    }

    function changeProjectRole() {
      const newArray = state.allCreatedChannel.map((item: any) => {
        return {
          id: item.id,
          isChecked: item.isChecked
        };
      });
      if (state.postLoading.changeProjectRole || !state.selectedMemberRole)
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_member_id: state.selectedMember.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_role_id: state.selectedMemberRole,
        channels: newArray,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: state.selectedMember.user.id,

      };
      state.postLoading.changeProjectRole = true;
      ethitransService
        .changeProjectRole(payLoad)
        .then((res: any) => {
          // console.log("changeProjectRole Response Check:>>> ", res.data.data);
          // state.memberList = res.data.data ? res.data.data : [];
          // state.selectedMember = {};
          // context.emit( )
          getProjectDetail();
          getProjectChannel();
          getProjectMemberRoleList();
          // emit("savemember", res.data.data);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            if (state.checkActivity) {
              // state.selectedMember = {};
              // state.selectedMember = state.activeMemberList.find((meb: any) => {
              //   meb.id === state.selectedMember.id
              // });
              state.selectedMemberRole = payLoad.project_role_id;
              state.allCreatedChannel = state.channelList.map((item: any) => {
                if (item.active_member.some((data: any) => data.user.email === state.selectedMember.user.email)) {
                  return { ...item, isChecked: true };
                } else {
                  return { ...item, isChecked: false };
                }
              });
              state.data.selectedValidityDate = new Date(
                `${state.selectedMember.validity_date}`
              );
              state.checkActivity = false;
            } else {
              hideChangeRole();
            }
          }, 2000);
          state.postLoading.changeProjectRole = false;
        });
    }
    function selectRole() {
      if (state.selectedMemberRole == 2) {
        state.allCreatedChannel = state.channelList.map((item: any) => ({ ...item, isChecked: true }));

      }
      if (state.selectedMemberRole == 3) {
        state.allCreatedChannel = state.channelList.map((item: any) => {
          if (item.active_member.some((data: any) => data.user.email === state.selectedMember.user.email)) {
            return { ...item, isChecked: true };
          } else {
            return { ...item, isChecked: false };
          }
        });
      }
      if (state.selectedMemberRole == 2 || state.selectedMemberRole == 3) {
        state.checkActivity = true;
        changeProjectRole();
      }
    }
    function changeChannelCheckBox(channel: any) {
      if (state.selectedMemberRole == 2 || state.selectedMemberRole == 3) {
        channel.isChecked = !channel.isChecked;
        state.checkActivity = true;
        changeProjectRole();
      }
    }
    function validateInviteForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.users.length && !state.invite.filteredInvites.length) {
        state.errorList.push("Invite member is required.");
      }
      if (!state.selectedAllChannel.length) {
        state.errorList.push("Channel is required.");
      }
      if (selectedValue.value === "4" && !selectedDateForMember.value) {
        state.errorList.push("Please select the date.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      // if (state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '' || (item.smsNotification && (item.phone == '')))) {
      state.invite.invites.forEach((invite: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.first_name == '') {
          invite.fNameError = true;
          state.errorList.push("First Name must be filled out to continue.");
        } else {
          invite.fNameError = false;
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.last_name == '') {
          invite.lNameError = true;
          state.errorList.push("Last Name must be filled out to continue.");
        } else {
          invite.lNameError = false;
        }
        if (invite.smsNotification) {
          if (invite.phone == '') {
            invite.phoneError = true;
            state.errorList.push("Mobile Number must be filled out to continue.");
            //   state.errorList.push(${`'Mobile Number must be filled out to continue for '${invite.first_name}.`});
          }
          else if (invite.phone.length != 11) {
            invite.phoneError = true;
            state.errorList.push("Mobile Number must be of 10 digit.");
          } else {
            invite.phoneError = false;
          }
        }

      })

      // }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }

    function createPrivateChannelValidation() {
      // validateInviteForm();
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.filteredUsers.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      // if (state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '')) {
      state.invite.invites.forEach((invite: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.first_name == '') {
          invite.fNameError = true;
          state.errorList.push("First Name Last name is requred for  all invitation.");
        } else {
          invite.fNameError = false;
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (invite.last_name == '') {
          invite.lNameError = true;
          state.errorList.push("First Name Last name is requred for  all invitation.");
        } else {
          invite.lNameError = false;
        }
        if (invite.smsNotification) {
          if (invite.phone == '') {
            invite.phoneError = true;
            state.errorList.push("Mobile Number must be filled out to continue.");
            state.isErrorStatus = true;
            //   state.errorList.push(${`'Mobile Number must be filled out to continue for '${invite.first_name}.`});
          }
          else if (invite.phone.length != 11) {
            invite.phoneError = true;
            state.errorList.push("Mobile Number must be of 10 digit.");
            state.isErrorStatus = true;
          } else {
            invite.phoneError = false;
          }
        }
      })
      // state.errorList.push("First Name Last name is requred for  all invitation.");
      // }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }

    function inviteAllUsers() {
      if (state.data.subscriptionDetails.total_channel_member === state.userList.length) {
        // if (1 === state.activeMemberList.length) {

        state.ui.isActive.upgradeSubscription = true;
      } else {
        // state.invite.filteredUsers = [];
        state.invite.filteredInvites = [];
        state.sendChannelId = [];
        state.selectedAllChannel.forEach((item: any) => {
          state.sendChannelId.push(item.id);
        });
        // state.invite.users.forEach((item: any) => {
        //   // console.log("CHECK email", item);
        //   state.invite.filteredUsers.push(item.email);
        // });
        state.invite.invites.forEach((user: any) => {
          const data = {
            email: user.email,
            // eslint-disable-next-line @typescript-eslint/camelcase
            first_name: user.first_name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            last_name: user.last_name,
            // eslint-disable-next-line @typescript-eslint/camelcase
            phone: (user.phone.length == 10) ? "1" + user.phone : user.phone,
            mailNotification: user.mailNotification,
            smsNotification: user.smsNotification,
          };
          state.invite.filteredInvites.push(data);
        });
        if (state.inviteMember && state.invite.invites.length === 0) {
          if (validationService.isValidEmail(state.inviteMember)) {
            // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
            state.invite.filteredInvites.push(state.inviteMember);
          }
        }
        validateInviteForm();
        if (
          state.errorList.length != 0 ||
          state.isErrorStatus ||
          state.postLoading.inviteAllUsers
        )
          return false;
        let payLoad: any = {};

        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: projectId,
          channels: state.sendChannelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invitation_type: "channel_invitation",
          // eslint-disable-next-line @typescript-eslint/camelcase
          user_type: state.data.inviteAsGuest ? "guest" : "",
          // users: common.removeDuplicate(state.invite.filteredUsers),
          invites: common.removeDuplicate(state.invite.filteredInvites),
          // eslint-disable-next-line @typescript-eslint/camelcase
          message_share: {
            type: selectedValue.value,
          }
        };
        if (selectedValue.value === "4") {
          payLoad.message_share.date = selectedDateForMember.value;
          // selectedDateForMember
        }
        // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
        console.log("CHECK PAYLOAD ON API CALL", payLoad);
        // sendChannelId
        cookie.setCookie('channelList', state.sendChannelId)

        // Save the channels Selected
        // all channels
        // allChannels 
        // Selected Channels Here
        // state.selectedAllChannel


        state.postLoading.inviteAllUsers = true;
        ethitransService
          .invitation(payLoad)
          .then((res: any) => {
            console.log("Users Invite Check:>>> ", res.data.message);
            hideAddMember();
            showSuccessModal();
            state.data.invitationVerifyMsg = "";
            state.data.invitationVerify.newMemberList = [];
            state.data.invitationVerify.registeredMemberList = [];
            state.data.invitationVerify.pendingMemberList = [];
            state.invitationMessage = res.data.message ? res.data.message : "";
            getProjectChannel();
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.postLoading.inviteAllUsers = false;
          });
      }
    }


    function invitationShareProjectPhase() {
      console.log("senddata", state.data.selectSharePhase);
      state.invite.filteredUsers = [];
      state.invite.users.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item.email);
      });
      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item);
      });
      validationInviteSharePhase();
      if (state.errorList.length != 0 || state.isErrorStatus || state.postLoading.inviteProjectUsers) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        emails: common.removeDuplicate(state.invite.filteredUsers),
        phases: state.data.selectSharePhase,
        individual: state.data.invitationProjectVerify.isIndividual
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.postLoading.inviteProjectUsers = true;
      ethitransService
        .inviteProjectShare(payLoad)
        .then((res: any) => {
          hideaddShareProject();
          state.data.showSuccesCustomModal = true;
          state.data.successFulMessage = "Invite sent sucessfully";
          state.invite.invites = []
          state.phaseOptions = [];
          state.invite.filteredUsers = [];
          state.invite.users = [];
          state.data.invitationProjectVerify.newMemberList = [];
          getProjectDetail();
          state.data.invitationProjectVerify.isIndividual = false;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading.inviteProjectUsers = false;
          state.data.invitationProjectVerify.showStatus = false;
        });
    }
    function channelMemberTest() {
      if (state.data.inviteAsGuest) {
        console.log("Guest");
      } else if (state.data.inviteAsPublicUser) {
        console.log("Public");
      } else {
        console.log("Normal Member");
      }
      if (state.data.subscriptionDetails.guests_per_project === state.userList.length) {
        // if (1 === state.activeMemberList.length) {

        state.ui.isActive.upgradeSubscription = true;
        return false;
      } else {
        console.log("CHeck the list")
      }

    }

    function inviteAllPublicUser() {

      state.sendChannelId = [];
      state.selectedAllChannel.forEach((item: any) => {
        state.sendChannelId.push(item.id);
      });
      validationPublicUser();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        channels: state.sendChannelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        // channel_id: state.data.selectedChannelByProject.channel.uuid,
        users: common.removeDuplicate(state.invite.filteredUsers),
        invites: state.inviteAsPublic,
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.postLoading.inviteAllUsers = true;
      ethitransService
        .invitationPublicUser(payLoad)
        .then((res: any) => {
          console.log("Users Invite Check:>>> ", res.data.message);
          hideAddMember();
          showSuccessModal();
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.invitationMessage = res.data.message ? res.data.message : "";
          getProjectChannel();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading.inviteAllUsers = false;
          state.data.inviteAsPublicUser = false;
        });
    }

    function verifyInvitation() {
      if (state.data.subscriptionDetails.total_channel_member === state.userList.length) {
        // if (1 === state.activeMemberList.length) {

        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.invite.filteredUsers = [];
        state.invite.filteredInvites = [];
        state.invite.users.forEach((item: any) => {
          // console.log("CHECK email", item);
          state.invite.filteredUsers.push(item.email);
        });
        if (state.data.inviteAsPublicUser) {
          state.inviteAsPublic.forEach((item: any) => {
            item.phone = `+1${item.phone}`;
          });
          // console.log("CHECK email", item);
          state.invite.filteredInvites = state.inviteAsPublic;
          // });
        } else {
          state.invite.invites.forEach((item: any) => {
            // console.log("CHECK email", item);
            state.invite.filteredInvites.push(item);
          });
        }
        if (state.inviteMember && state.invite.invites.length === 0) {
          if (validationService.isValidEmail(state.inviteMember)) {
            // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
            state.invite.filteredInvites.push(state.inviteMember);
          }
        }
        validateInviteForm();
        if (
          state.errorList.length != 0 ||
          state.isErrorStatus ||
          state.ui.isPostLoading.verifyInvitation
        )
          return false;

        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: state.data.selectedChannelByProject.channel.uuid,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invitation_type: "channel_invitation",
          users: common.removeDuplicate(state.invite.filteredUsers),
          invites: common.removeDuplicate(state.invite.filteredInvites),
        };
        // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
        console.log("CHECK PAYLOAD ON API CALL", payLoad);
        // state.postLoading.inviteAllUsers = true;
        state.ui.isPostLoading.verifyInvitation = true;
        ethitransService
          .verifyInvitation(payLoad)
          .then((res: any) => {
            state.data.invitationVerify.showStatus = true;
            // state.data.invitationVerifyMsg = ''
            state.ui.isPostLoading.verifyInvitation = false;
            console.log("verifyInvitation Check:>>> ", res.data.data);
            // console.log("verifyInvitation Check:>>> ", );
            state.data.invitationVerify.newMemberList = res.data.data.new_members
              ? res.data.data.new_members
              : [];
            state.data.invitationVerify.registeredMemberList = res.data.data
              .registered_members
              ? res.data.data.registered_members
              : [];
            state.data.invitationVerify.pendingMemberList = res.data.data
              .pending_members
              ? res.data.data.pending_members
              : [];
            if (res.data.data) {
              state.data.invitationVerifyMsg = res.data.data;
            } else {
              inviteAllUsers();
            }

            // hideAddMember();
            // showSuccessModal();
            // state.invitationMessage = res.data.message ? res.data.message : "";
            // getProjectDetail();
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.isPostLoading.verifyInvitation = false;
          });
      }
    }

    function verifyProjectInvitation() {
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.invite.users.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item.email);
      });

      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item);
      });

      if (state.inviteMember && state.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          state.invite.filteredInvites.push(state.inviteMember);
        }
      }
      validationInviteSharePhase();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.ui.isPostLoading.verifyProjectInvitation
      )
        return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "project_invitation",
        emails: common.removeDuplicate(state.invite.filteredUsers),
        phases: state.data.selectSharePhase,
        individual: state.data.invitationProjectVerify.isIndividual

      };
      state.ui.isPostLoading.verifyProjectInvitation = true;
      state.data.invitationProjectVerify.showStatus = true;
      ethitransService
        .verifyProjectInvitation(payLoad)
        .then((res: any) => {
          state.ui.isPostLoading.verifyProjectInvitation = false;
          state.data.invitationProjectVerify.newMemberList = res.data.data
            ? res.data.data
            : [];
          state.data.invitationProjectVerify.registeredMemberList = res.data.data
            .shared_members
            ? res.data.data.shared_members
            : [];
          state.data.invitationProjectVerify.pendingMemberList = res.data.data
            .pending_members
            ? res.data.data.pending_members
            : [];
          if (res.data.data) {
            state.data.invitationProjectVerifyMsg = res.data.data;
          } else {
            invitationShareProjectPhase();
          }

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.verifyProjectInvitation = false;
        });
    }

    function validateForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.createChannel.name) {
        state.errorList.push("Channel name is required.");
      }
      if (!state.createChannel.description) {
        state.errorList.push("Channel description is required.");
      }
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }
    }

    function createChannel() {
      // state.error.errorList.createChannel = [];
      // state.error.hasError = false;
      validateForm();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.postLoading.createChannel
      )
        return false;
      const userList = state.coOwner.filter((item: any) => item.isChecked).map((item: any) => item.user.id);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        name: state.createChannel.name,
        description: state.createChannel.description,
        member: state.isIcludeCoOwner ? userList : [],
      };
      state.postLoading.createChannel = true;
      ethitransService
        .createChannel(payLoad)
        .then((res: any) => {
          // console.log("Project List Response Check:>>> ", res.data.data);
          state.data.currentChannel = res.data.data;
          state.channelList.unshift(res.data.data);
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Created Successfully.";
          state.data.subTitleMessage = "Now invite members so they can participate.";
          state.data.isChannelCreated = true;
          hideCreateChannel();

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          state.postLoading.createChannel = false;
        });
    }

    function getProjectRoleList() {
      // console.log("HELLO GROM ROLE LIST FROM PROJET DETAILS");
      // state.isLoading.getProjectRoleList = true;
      ethitransService
        .projectRoleList()
        .then((res: any) => {
          // console.log("getProjectRoleList Response Check:>>> ", res.data.data);
          // state.roleList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.isLoading.getProjectRoleList = false;
        });
    }
    function closeSuccessModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
      if (state.data.allProject > 0 && state.data.allChannel >= 0 && state.data.allMember == 0) {
        setTimeout(() => {
          showTour('addMember', 'Add Member', ' Get started with your project by adding new member(s)');
        }, 100);
      }
    }
    function closeProjectImageUploadModal() {
      state.data.showProjectImageUploadModal = !state.data.showProjectImageUploadModal;
    }
    function redirectToProjectToDo() {
      router.push({
        name: "user-todo-list",
        params: { projectId: projectId },
      });
    }
    // private channel pusher implemented
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const pusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      // authEndpoint: 'http://localhost:8000/api/broadcasting/auth',
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + newToken,
        },
      },
    });

    const projectDetailsDashboard = pusher.subscribe(
      `private-projectDashboard.${projectId}`
    );
    // console.log("Channel Communication>>> ", channel);

    projectDetailsDashboard.bind(
      "projectDashboard.updated",
      function (data: any) {
        if (JSON.stringify(data)) {
          const url: any = window.location.href;
          // const url: any = window.location.href.split("#/").pop();
          const page: any = url.split("#/").pop().split("/")[0];
          // const channelId: any = url.split("/").pop();
          // state.data.channelId = "";
          if (page === "project-detail") {
            // state.data.channelId = channelId;
            getProjectDetail();
          }
        }
      }
    );

    function goToShareInvitation() {
      router.push({
        name: "share-invitation",
        params: { projectId: state.projectDetail.uuid },
      });
    }

    function goToShareInvitationEdit(id: any) {
      router.push({
        name: "share-invitation-edit",
        params: { editId: id },
      });
    }

    function redirectToProjectCalendar() {
      const userAllInfo: any = localStorage.getItem("current_user_info");
      const userInfo: any = JSON.parse(userAllInfo);
      const userUUId = localStorage.getItem("current_user_uuid") ? localStorage.getItem("current_user_uuid") : userInfo.uuid;


      router.push({
        name: "user-project-calendar",
        params: { userId: userUUId },
      });
    }


    function fetchCustomerDetails(email: string) {
      ethitransService.detailsByemail(email)
        .then(response => {
          console.log("DATA FETCH >>> ", response.data.data)

          const selectedCustomer: any = response.data;
          if (selectedCustomer && selectedCustomer.data) {
            const data = selectedCustomer.data;

            const customerData: any = {
              email: validationService.isValidEmail(data.email) ? data.email : null,
              phone: data.mobile_number || '',
              // eslint-disable-next-line @typescript-eslint/camelcase
              first_name: data.first_name || '',
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_name: data.last_name || '',
              smsNotification: validationService.validateMobileNumber(data.mobile_number) ? true : false,
              mailNotification: validationService.isValidEmail(data.email) ? true : false,
              previousEmail: validationService.isValidEmail(data.email) ? data.email : null,
              previousPhone: data.mobile_number || '',
              // mapNotifyByEmail : true,
              // mapNotifyBySms: true,
            };

            // Find and update the existing user in the invite list
            const existingCustomerIndex = state.invite.users.findIndex(
              // eslint-disable-next-line @typescript-eslint/camelcase
              (customer: any) => customer.email === data.email || customer.phone === data.mobile_number
            );
            if (existingCustomerIndex !== -1) {
              // Update the user details
              state.invite.invites[existingCustomerIndex] = { ...customerData };
            } else {
              // Add new customer if not found
              state.invite.invites.push(customerData);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching customer details:', error);
        });
    }

    function selectToChangeUsers(value: any) {
      const existingIndex = state.invite.users.some((obj: any) => obj.id === value.id);
      if (value.id == "convo101" && allUsers.value.length >= 2) {
        const filteredArray = allUsers.value.filter((item: any) => item.id !== "convo101");
        state.invite.users = state.invite.users.concat(filteredArray);
        allUsers.value = [
          {
            'id': 'convo101',
            'label': 'Select All users',
            'email': 'Select All users',
            'full_name': 'Select All users',
          }
        ];
      }
      else if (!existingIndex && value.id != "convo101") {
        state.invite.users.push(value);
        // filtered the selected
        const filteredArray = allUsers.value.filter((item: any) => item.id !== value.id);
        allUsers.value = filteredArray;
        if (value.email && value.phone) {
          fetchCustomerDetails(value.email);
        } else if (value.phone && !value.email) {
          fetchCustomerDetails(value.phone);
        } else {
          fetchCustomerDetails(value.email);
        }
      }
      selectedUsers.value = {};
    }




    function updateChannelPostion(item: any) {
      const payLoad = {
        channels: item,
      };
      ethitransService
        .channelReorder(payLoad)
        .then((res: any) => {
          // state.data.unitLists = res.data.data;

        })
        .catch((error: any) => {
          //
        })
        .finally(() => {
          // state.ui

        });
    }

    function orderChange() {
      const itemPostion = computed(() => {
        return state.channelList.map((item: any, index: any) => ({ id: item.id, index }));

      });
      updateChannelPostion(itemPostion.value);
    }

    function hideChannelShow(Channel: any) {
      state.data.channelId = Channel.id;
      $("#hideChannelModal").modal("show");
    }
    function hideChannelClose() {
      state.data.channelId = "";
      $("#hideChannelModal").modal("hide");
    }

    function showChannelShow(Channel: any) {
      state.data.channelId = Channel.id;
      $("#showChannelModal").modal("show");
    }
    function showChannelClose() {
      state.data.channelId = "";
      $("#showChannelModal").modal("hide");
    }


    function channelHide() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.channelId,
      };
      state.ui.isPostLoading.channelHide = true;
      ethitransService
        .channelHide(payLoad)
        .then((res: any) => {
          const indexToRemove = state.channelList.findIndex((item: any) => item.id === res.data.data.channel_id);
          const removeFromOptions = channelLists.value.findIndex((item: any) => item.id === res.data.data.channel_id);
          if (indexToRemove !== -1) {
            state.channelList.splice(indexToRemove, 1);
          }
          if (removeFromOptions !== -1) {
            channelLists.value.splice(indexToRemove, 1);
          }
          hideChannelClose();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel Successfully Hide.";
        })
        .catch((error: any) => {
          //
        })
        .finally(() => {
          state.ui.isPostLoading.channelHide = false;
        });
    }

    function channelShow() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.data.channelId,
      };
      state.ui.isPostLoading.channelShow = true;
      ethitransService
        .channelShow(payLoad)
        .then((res: any) => {
          const indexToRemove = state.channelList.findIndex((item: any) => item.id === res.data.data.channel_id);
          const removeFromOptions = channelLists.value.findIndex((item: any) => item.id === res.data.data.channel_id);
          if (indexToRemove !== -1) {
            state.channelList.splice(indexToRemove, 1);
          }
          if (removeFromOptions !== -1) {
            channelLists.value.splice(indexToRemove, 1);
          }
          showChannelClose();
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Channel is now successfully visible..";
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.channelShow = false;
        });
    }

    function addFavProjectShow() {
      $("#addFavProjectModal").modal("show");
    }
    function addFavProjectClose() {
      $("#addFavProjectModal").modal("hide");
    }

    function removeFavProjectShow() {
      $("#removeFavProjectModal").modal("show");
    }
    function removeFavProjectClose() {
      $("#removeFavProjectModal").modal("hide");
    }


    function projectFav() {
      state.ui.errors.errorList.projectFav = [];
      state.ui.errors.hasError = false;

      // validateForm();
      if (
        state.ui.errors.errorList.projectFav.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.projectFav
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectDetail.id,
      };
      state.ui.isPostLoading.projectFav = true;
      ethitransService
        .projectFav(payLoad)
        .then((res: any) => {
          addFavProjectClose();
          state.projectDetail.fav = 1;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = " Project successfully pinned.";
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectFav)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectFav.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.projectFav = false;
        });
    }

    function projectFavRemove() {
      state.ui.errors.errorList.projectFavRemove = [];
      state.ui.errors.hasError = false;

      // validateForm();
      if (
        state.ui.errors.errorList.projectFav.length != 0 ||
        state.ui.errors.hasError ||
        state.ui.isPostLoading.projectFavRemove
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectDetail.id,
      };
      state.ui.isPostLoading.projectFavRemove = true;
      ethitransService
        .projectFavRemove(payLoad)
        .then((res: any) => {
          removeFavProjectClose();
          state.projectDetail.fav = 0;
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          state.data.successFulMessage = "Project Unpinned.";

        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.projectFavRemove)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.projectFavRemove.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.projectFavRemove = false;
        });
    }

    function createPrivateChannel() {
      if (state.data.privateChatChannel.length >= state.data.subscriptionDetails.private_channel) {
        // if (state.data.privateChatChannel.length >= 3 ) {
        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.errorList = []
        state.selectPrivateChannel = '';
        allUsers.value = [];
        state.invite.users = [];
        state.invite.invites = [];
        state.activeMemberList.forEach((memb: any) => {
          if (memb.user.email != state.data.activeUserEmail) {
            allUsers.value.push({
              'id': memb.user.uuid,
              'label': memb.user.email,
              'email': memb.user.email,
              'full_name': memb.user.full_name,
              'phone': memb.mobile_number,
            })
          }

        })
        $("#createPrivateChannel").modal("show");
      }
    }

    function addMemberInPrivateChannel(data: any) {
      state.errorList = []
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      state.activeMemberList.forEach((memb: any) => {
        if (memb.user.email != state.data.activeUserEmail) {
          allUsers.value.push({
            'id': memb.user.uuid,
            'label': memb.user.email,
            'email': memb.user.email,
            'full_name': memb.user.full_name,
            'phone': memb.mobile_number,
          })
        }

      })
      state.selectPrivateChannel = data;
      $("#createPrivateChannel").modal("show");
    }

    function hidePrivateChannel() {
      state.selectPrivateChannel = '';
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      state.errorList = []
      $("#createPrivateChannel").modal("hide");
    }

    function createPrivateChannelGroup() {
      state.invite.filteredUsers = [];
      state.invite.invites.forEach((item: any) => {
        state.invite.filteredUsers.push(item);
      });

      createPrivateChannelValidation();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.postLoading.inviteAllUsers
      )
        return false;


      state.invite.filteredUsers.forEach((item: any) => {
        item.phone = `1${item.phone}`;
      });
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.selectPrivateChannel ? state.selectPrivateChannel.uuid : '',
        users: common.removeDuplicate(state.invite.filteredUsers),
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.postLoading.inviteAllUsers = true;
      ethitransService
        .createPrivateChannelGroup(payLoad)
        .then((res: any) => {
          const existingIndex = state.data.privateChatChannel.findIndex((item: any) => item.id === res.data.data.id);
          console.log('im', existingIndex)
          if (existingIndex !== -1) {
            state.data.privateChatChannel[existingIndex] = res.data.data;
          } else {
            state.data.privateChatChannel.unshift(res.data.data);
          }
          hidePrivateChannel();
          state.data.successFulMessage = "Private Channel Created Successfully";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          state.postLoading.inviteAllUsers = false;
          state.invite.filteredUsers = [];
        });
    }
    function gotoListJobEstimation() {
      router.push({
        name: "list-job-estimation",
        params: { projectId: projectId },
      });
    }

    const checkMobileDevice = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)');
      isMobile.value = mobileMediaQuery.matches;
    };

    // onMounted(() => {
    //   checkMobileDevice();

    //   // Update the isMobile value when the window is resized
    //   window.addEventListener('resize', checkMobileDevice);
    // });
    function goToSmartContract() {
      router.push({
        name: "smart-contract-list",
        params: { projectId: projectId },
      });
    }


    function masterTagList() {
      ethitransService
        .masterTagList()
        .then((res: any) => {
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
          options.value = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common
          //   .sdCatchErr(error, state.ui.error.errorsList.masterTagList)
          //   .then((res: any) => {
          //     state.ui.error.isErrorStatus = true;
          //     for (const [key, value] of Object.entries(res)) {
          //       if (Array.isArray(value)) {
          //         state.ui.error.errorsList.masterTagList.push(value[0]);
          //       }
          //     }

          //   });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function renameProjectModal() {
      isEdit.value = false;
      masterTagList();
      selectedOptions.value = [];
      state.data.selectedProject = state.projectDetail;
      state.data.selectedProjectEdit.projectName =
        state.data.selectedProject.name;
      state.data.selectedProjectEdit.projectDescription =
        state.data.selectedProject.description;
      latitude.value = state.data.selectedProject.latitude;
      longitude.value = state.data.selectedProject.longitude;
      address.value = state.data.selectedProject.full_address;
      state.data.selectedProject.tags.forEach((tag: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        selectedOptions.value.push(tag.master_tag);
      });
      $("#renameModal").modal("show");
    }
    function hideRenameProjectModal() {
      state.data.selectedProject = {};
      state.data.tags.value = [];
      state.ui.successMessage = '';
      getProjectDetail();
      $("#renameModal").modal("hide");

    }

    function checkEdit() {
      isEdit.value = !isEdit.value;
    }

    function saveEditProject() {
      state.data.tags.value = [];
      state.ui.successMessage = '';
      selectedOptions.value.forEach((tag: any) => {
        state.data.tags.value.push(tag.code)
      });
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        id: state.data.selectedProject.id,
        name: state.data.selectedProjectEdit.projectName,
        description: state.data.selectedProjectEdit.projectDescription,
        tags: state.data.tags.value,
        latitude: latitude.value,
        longitude: longitude.value,
        // eslint-disable-next-line @typescript-eslint/camelcase
        full_address: address.value
      };
      console.log("Payload Check >>> ", payLoad);
      state.postLoading.saveEditProject = true;
      ethitransService
        .projectUpdate(payLoad)
        .then((res: any) => {
          isEdit.value = false;
          state.data.selectedProject.name = state.data.selectedProjectEdit.projectName;
          state.ui.successMessage = 'Project details updated successfully!';
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.saveEditProject)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.saveEditProject.push(value[0]);
                }
              }
            });
        })

        .finally(() => {
          state.postLoading.saveEditProject = false;
        });
    }
    function modifyHistoryDateForMember() {
      state.ui.errors.errorList.modifyHistoryDateForMember = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.modifyHistoryDateForMember) return false;
      let payLoad: any = {};
      payLoad = {

        id: state.data.selectedPendingMember.channel.uuid,
        email: state.data.selectedPendingMember.email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        contact_number: state.data.selectedPendingMember.contact_number,
        // eslint-disable-next-line @typescript-eslint/camelcase
        message_share: {
          type: selectedValueForPending.value,
        }
      };
      if (selectedValueForPending.value === "4") {
        payLoad.message_share.date = selectedDateForMember.value;
        // selectedDateForMember
      }
      state.ui.isPostLoading.modifyHistoryDateForMember = true;
      console.log("Payload Check >>> ", payLoad);
      state.postLoading.saveEditProject = true;
      ethitransService
        .convHistoryForUser(payLoad)
        .then((res: any) => {
          state.data.selectedProject.name = state.data.selectedProjectEdit.projectName;
          state.ui.successMessage = 'Project details updated successfully!';
          state.ui.isPostLoading.modifyHistoryDateForMember = false;
          hideHistoryMember()
        })
        .catch((error: any) => {
          // state.ui.isPostLoading.modifyHistoryDateForMember = false;
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.modifyHistoryDateForMember)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.modifyHistoryDateForMember.push(value[0]);
                }
              }
            });
        })

      // .finally(() => {
      //   state.ui.isPostLoading.modifyHistoryDateForMember = false;
      // });
    }

    onMounted(() => {
      getUserList();
      getProjectDetail();
      getProjectRoleList();
      state.data.imageBaseUrl = config.imageBaseUrl;
      dropDownChannelList();
      getProjectChannel();
      checkMobileDevice();
      // Update the isMobile value when the window is resized
      window.addEventListener('resize', checkMobileDevice);
    });
    function hideUpdateSubscriptionModal() {
      state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
    }
    return {
      state,
      checkEmptyFields,
      handleFilteredObjects,
      getObjectById,
      modifyHistoryDateForMember,
      hideHistoryMember,
      selectedValueForPending,
      selectedDateForPendingMember,
      // disableCheckInvities,
      selectedDateForMember,
      selectedValue,
      phoneNumber,
      hasValidLastContact,
      addNewMember,
      hideUpdateSubscriptionModal,
      channelMemberTest,
      goToSmartContract,
      smartContractAccess,
      gotoListJobEstimation,
      deletePublicMember,
      changeInvitePublicMember,
      privateChat,
      resendProjectRequest,
      hideEditProjectAcknowledgementModal,
      redirectToDoc,
      hideProjectAcknowledgementRequestDetails,
      showProjectAcknowledgementRequestDetails,
      redirectToPhaseChat,
      viewSelectedPhaseDetails,
      setAcknowledgedData,
      hideProjectAcknowledgementModal,
      showProjectAcknowledgementModal,
      createProjectToDoValidation,
      hideCreateToDoModal,
      showCreateToDoModal,
      createProjectToDo,
      redirectToProjectToDo,
      showCreateProjectChannel,
      toggleTourGuide,
      saveEditChannel,
      showEditChannelModal,
      hideEditChannelModal,
      hideUserProfile,
      hidePendingMemberModal,
      hideSuccessInvitationModal,
      closeSuccessModal,
      showResendInvitationConfirmation,
      hideResendInvitationConfirmation,
      channelPendingMemberRemapping,
      channelMemberCountRemapping,
      hidePendingMemberRemove,
      selectPendingMemberForDeletes,
      modifyUserConversationHistoryDate,
      deletePendingInvitation,
      backInvitation,
      verifyInvitation,
      addCreateChannel,
      resendInvitation,
      getAccountFormList,
      getUserList,
      getProjectDetail,
      createChannel,
      showCreateChannel,
      hideCreateChannel,
      addMember,
      hideAddMember,
      showSuccessModal,
      inviteAllUsers,
      changeExistingMember,
      changeInviteMember,
      removeSelectedUser,
      removeSelectedMember,
      removeSelectedChannel,
      callBackMembers,
      validateForm,
      validateInviteForm,
      isUserStatus,
      changeProjectRole,
      getProjectMemberRoleList,
      changeRole,
      selectForMemberRemove,
      removeProjectRole,
      hideProjectMemberRemove,
      getChannelByProject,
      showPendingList,
      showMemberList,
      projectMemberListByChannel,
      setNewValidity,
      hideMemberModal,
      inviteAllPublicUser,
      validationPublicUser,
      hideaddShareProject,
      addShareProject,
      getShareProjectPhaseList,
      validationInviteSharePhase,
      invitationShareProjectPhase,
      checkAllPhases,
      showProjectShareSuccessModal,
      verifyProjectInvitation,
      backProjectInvitation,
      shareProjectInvitationDetails,
      hideEditShareProject,
      editShareProject,
      projectShareInvitationEdit,
      validationInviteSharePhaseUpdate,
      invitationShareProjectPhaseUpdate,
      goToShareInvitation,
      goToShareInvitationEdit,
      hideShareProjectRemove,
      selectShareProjectRemove,
      removeShareInvitation,
      redirectToProjectCalendar,
      selectedChannels,
      allChannels,
      selectToChange,
      getProjectChannel,
      changeChannelStatus,
      searchChannel,
      channelLists,
      channelListSelected,
      dropDownChannelList,
      searchChannelRemove,
      orderChange,
      updateChannelPostion,
      hideChannelShow,
      hideChannelClose,
      showChannelShow,
      showChannelClose,
      channelHide,
      channelShow,
      isMobile,
      closeProjectImageUploadModal,
      selectedUsers,
      allUsers,
      selectToChangeUsers,
      addFavProjectShow,
      addFavProjectClose,
      removeFavProjectShow,
      removeFavProjectClose,
      projectFav,
      projectFavRemove,
      selectRole,
      changeChannelCheckBox,
      createPrivateChannel,
      hidePrivateChannel,
      createPrivateChannelGroup,
      createPrivateChannelValidation,
      nameWithEmail,
      addMemberInPrivateChannel,
      showTour,
      options,
      selectedOptions,
      latitude,
      longitude,
      address,
      isEdit,
      updateLatitude,
      updateLongitude,
      updateAddress,
      renameProjectModal,
      hideRenameProjectModal,
      saveEditProject,
      checkEdit
    };
  },
});
